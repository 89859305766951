@import url("https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto+Slab|Roboto:300,400,500,700");

* {
  box-sizing: "border-box";
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: "antialiased";
  -moz-osx-font-smoothing: "grayscale";
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
}
